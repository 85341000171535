import { Component } from '@angular/core';

import { AppStateService } from 'src/app/core/auth/services';

@Component({
	selector: 'pkd-navbar',
	standalone: true,
	imports: [],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
	constructor(private appStateService: AppStateService) {}

	get agentName() {
		return this.appStateService.promoCode.agent.name;
	}

	get logo() {
		const type =
			this.appStateService.promoCode.agent.eCommerceWebsite?.heroLogoAttachment.contentType +
			'/' +
			this.appStateService.promoCode.agent.eCommerceWebsite?.heroLogoAttachment.contentSubType;
		return 'data:' + type + ';base64, ' + this.appStateService.promoCode.agent.eCommerceWebsite?.heroLogoAttachmentBase64.response;
	}
}
