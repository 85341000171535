import { Agent } from './agent';

export class PromoCode {
	public id: number;
	public slug: string;
	public isDefault: boolean;

	public agent: Agent;

	constructor(promoCode: any) {
		Object.assign(this, promoCode);

		this.agent = this.agent ? new Agent(this.agent) : this.agent;
	}
}
