import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from 'src/environments/environment';
import { AppStateService } from '../auth/services/app-state.service';

@Injectable({
	providedIn: 'root',
})
export class AuthResolver implements Resolve<boolean> {
	constructor(
		private router: Router,
		private appStateService: AppStateService,
		private platformLocation: PlatformLocation,
	) {}

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		try {
			const isPkd = this.platformLocation.href.includes('localhost') || this.platformLocation.href.includes('powerkioskdirect');

			let salesCode = environment.production ? 'pkec' : 'pkec_test';
			if (route.queryParamMap.get('salesCode')) {
				salesCode = route.queryParamMap.get('salesCode');
			} else if (!isPkd) {
				salesCode = '';
			}

			await this.appStateService.setPromoCode(isPkd, salesCode);

			if ((!this.appStateService.isPlatformBrowser && state.url !== '/') || !this.appStateService.promoCode) {
				await this.router.navigate(['/']);
			}

			if (this.appStateService.promoCode) {
				this.appStateService.setReady({ app: true });
			}

			return true;
		} catch (e) {
			console.error(e);
		}

		return false;
	}
}
