export const environmentShared = {
	timezone: 'America/Chicago',
	dateFormat: 'MMM dd, yyyy',
	production: false,
	baseUrl: '',
	graphQLEndpoint: '',
	apiKey: '',
	apiKeys: {
		// put alphabetically to make it easy to find
		'http://localhost:4200': '27d38faf56e04e039a1c22706939fa13',
		'http://localhost:4000': '27d38faf56e04e039a1c22706939fa13',
		'https://newtest.powerkioskdirect.com': '27d38faf56e04e039a1c22706939fa13',
		'https://newdemo.powerkioskdirect.com': 'b07c21dc18b04e33b095a01612e5902a',
		'https://new.powerkioskdirect.com': 'bd6b39ccd68cac3e2efa3a624367529b',
	},
};
