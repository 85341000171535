import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Subject } from 'rxjs';

import { PromoCode } from 'src/app/shared/models/promo-code';
import { GraphqlService } from '../../grahpql/services';

@Injectable({
	providedIn: 'root',
})
export class AppStateService {
	public isPlatformBrowser: boolean;

	public onReady = new Subject<{ app: boolean }>();
	public promoCode: PromoCode;

	constructor(
		@Inject(PLATFORM_ID) private platformId: object,
		private graphqlService: GraphqlService,
	) {
		this.isPlatformBrowser = isPlatformBrowser(this.platformId);
	}

	// temporary until we can add it to configuration
	get isSunnova() {
		return ['6935369fc47b4fcd81f35bf5a64e4308', '10e80ffb0aad4141bbf44dc5a7b742a8', '4683c850dea548f3a4c3fdf96a5068df'].includes(
			this.promoCode?.agent.agentId,
		);
	}

	public setReady(ready: { app: boolean }): void {
		this.onReady.next(ready);
	}

	public async setPromoCode(isPkd: boolean, salesCode: string): Promise<void> {
		if (this.promoCode) {
			return;
		}

		const result = await this.graphqlService.query<{ promoCodes: { message: PromoCode[] } }>(
			`
			query ECommerceAgent($salesCode: String) {
				${isPkd ? 'promoCodes(criteria: {isActive: true, slug: $salesCode}) {' : 'promoCodes(criteria: {isActive: true, slugOrDefault: true, slug: $salesCode}) {'}
					message {
						id
						isDefault
						slug
						agent {
							agentId
							parentId
							name
							eCommerceWebsites(criteria: { isNew: true }) {
								contactPhoneNumber
								contactEmail
								contactAddress1
								contactAddress2
								contactCity
								contactState {
									stateLong
									stateShort
								}
								contactZipCode
								contactPhoneNumberCountry {
									id
									code
								}
								heroLogoAttachment {
									contentType
									contentSubType
								}
								heroLogoAttachmentBase64 {
									response
								}
								navbarLogoAttachment {
									contentType
									contentSubType
								}
								navbarLogoAttachmentBase64 {
									response
								}
								footerLogoAttachment {
									contentType
									contentSubType
								}
								footerLogoAttachmentBase64 {
									response
								}
							}
							logins {
								isActive
								emails {
									email
								}
							}
						}
					}
				}
			}
		`,
			{ salesCode },
		);

		// get parent default, then try own default
		this.promoCode = new PromoCode(
			result.data.promoCodes.message.find(p => p.isDefault && !p.agent.parentId) ?? result.data.promoCodes.message.find(p => p.isDefault),
		);

		if (this.isPlatformBrowser) {
			let link = document.querySelector("link[rel~='icon']") as any;
			if (!link) {
				link = document.createElement('link') as any;
				link.rel = 'icon';
				document.head.appendChild(link);
			}
			if (isPkd) {
				link.href = 'https://assets.powerkiosk.com/newpkd/favicon.ico';
			} else {
				link.href = 'https://assets.powerkiosk.com/newpkd/quick-favicon.ico';
			}

			this.setColorVariables();
			window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
				this.setColorVariables();
			});
		}
	}

	// temporary hard code until we add this to configuration
	// at that point, move code into a color service
	private setColorVariables(): void {
		const root = document.querySelector(':root') as any;
		const setVariables = (vars: { [key: string]: string }) => Object.entries(vars).forEach(v => root.style.setProperty(v[0], v[1]));
		const colorVariables =
			window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? this.getDarkColorVariables() : this.getLightColorVariables();
		setVariables(colorVariables);
	}

	private getLightColorVariables(): { [key: string]: string } {
		return this.isSunnova
			? {
					'--color-white': '#ffffff',
					'--color-off-white': '#fafafa',
					'--color-black': '#000000',
					'--color-primary': '#0e0424',
					'--color-secondary': '#dc5600',
					'--color-text-color': '#070707',
					'--color-link-color': ' #dc5600',
					'--color-warning': '#ff8200',
					'--color-success': '#009900',
					'--color-danger': '#ff0000',
					'--color-light-gray': '#ebebeb',
					'--color-table-gray': '#f5f5f5',
					'--color-outline-gray': '#bebebe',
					'--color-med-gray': '#dfdfdf',
					'--color-dark-blue': '#0e0438',
					'--color-blue-gray': '#6c91a7',
					'--color-med-blue': '#210f66',
					'--color-tooltip': '#cfe1fb',
					'--color-form-control': '#F5F5F5',
					'--color-placeholder': '#757575',
					'--color-form-validation': '#fff1f1',
				}
			: {
					'--color-white': ' #ffffff',
					'--color-off-white': ' #fafafa',
					'--color-black': ' #000000',
					'--color-primary': ' #1a2574',
					'--color-secondary': ' #fc8f3b',
					'--color-text-color': ' #070707',
					'--color-link-color': ' #12a5ff',
					'--color-warning': ' #ff8200',
					'--color-success': ' #009900',
					'--color-danger': ' #ff0000',
					'--color-light-gray': ' #ebebeb',
					'--color-table-gray': ' #f5f5f5',
					'--color-outline-gray': ' #bebebe',
					'--color-med-gray': ' #dfdfdf',
					'--color-dark-blue': ' #214a86',
					'--color-blue-gray': ' #6c91a7',
					'--color-med-blue': ' #27569c',
					'--color-tooltip': ' #cfe1fb',
					'--color-form-control': ' #f5f5f5',
					'--color-placeholder': ' #757575',
					'--color-form-validation': ' #fff1f1',
				};
	}

	private getDarkColorVariables(): { [key: string]: string } {
		return this.isSunnova
			? {
					'--color-white': '#ffffff',
					'--color-off-white': '#fafafa',
					'--color-black': '#000000',
					'--color-primary': '#0e0424',
					'--color-secondary': '#dc5600',
					'--color-text-color': '#070707',
					'--color-link-color': '#dc5600',
					'--color-warning': '#ff8200',
					'--color-success': '#009900',
					'--color-danger': '#ff0000',
					'--color-light-gray': '#ebebeb',
					'--color-table-gray': '#f5f5f5',
					'--color-outline-gray': '#bebebe',
					'--color-med-gray': '#dfdfdf',
					'--color-dark-blue': '#0e0438',
					'--color-blue-gray': '#6c91a7',
					'--color-med-blue': '#210f66',
					'--color-tooltip': '#cfe1fb',
					'--color-form-control': '#F5F5F5',
					'--color-placeholder': '#757575',
					'--color-form-validation': '#fff1f1',
				}
			: {
					'--color-white': '#ffffff',
					'--color-off-white': '#fafafa',
					'--color-black': '#000000',
					'--color-primary': '#1a2574',
					'--color-secondary': '#fc8f3b',
					'--color-text-color': '#070707',
					'--color-link-color': '#12a5ff',
					'--color-warning': '#ff8200',
					'--color-success': '#009900',
					'--color-danger': '#ff0000',
					'--color-light-gray': '#ebebeb',
					'--color-table-gray': '#f5f5f5',
					'--color-outline-gray': '#bebebe',
					'--color-med-gray': '#dfdfdf',
					'--color-dark-blue': '#214a86',
					'--color-blue-gray': '#6c91a7',
					'--color-med-blue': '#27569c',
					'--color-tooltip': '#cfe1fb',
					'--color-form-control': '#f5f5f5',
					'--color-placeholder': '#757575',
					'--color-form-validation': '#fff1f1',
				};
	}
}
