import { ECommerceWebsite } from './';

export class Agent {
	public agentId: string;
	public parentId: string;
	public name: string;

	public eCommerceWebsites: ECommerceWebsite[];

	constructor(agent?: Agent) {
		Object.assign(this, agent);
	}

	get eCommerceWebsite() {
		return this.eCommerceWebsites.length ? this.eCommerceWebsites[0] : null;
	}
}
